<template>
  <div class="relative w-full h-32">
    <line-chart
      v-if="actual && forecast"
      ref="lineChart"
      class="h-32 absolute w-full"
      :chart-data="parsedChartData"
      :month="month"
      :currency="currency"
      :target-type="targetType"
    ></line-chart>
  </div>
</template>

<script>
import LineChart from '@/components/chart/bbcharts/base/LineChart.vue'

export default {
  name: 'BbChart',
  components: {
    LineChart,
  },
  props: {
    actual: {
      type: Array,
      required: true,
    },
    forecast: {
      type: Array,
      required: true,
    },
    threshold: {
      type: String,
      default: 'good',
      required: true,
    },
    month: {
      type: Number,
      required: true,
    },
    currency: {
      type: String,
      required: false,
    },
    targetType: {
      type: String,
      required: false,
    },
  },
  computed: {
    chartColors() {
      let borderColor, backgroundColor, backdropColor, backgroundColorTop, backgroundColorBottom
      switch (this.threshold) {
        case 'good':
          borderColor = 'rgba(24, 176, 112, 1)'
          backgroundColor = 'rgba(171, 230, 210, 0.5)'
          backgroundColorTop = 'rgba(24, 176, 112, 0.4)'
          backgroundColorBottom = 'rgba(211, 232, 229, 0.4)'
          backdropColor = 'rgba(230, 254, 250, 0.5)'
          break
        case 'bad':
          borderColor = 'rgba(215, 54, 60, 1)'
          backgroundColor = 'rgba(255, 221, 220, 0.5)'
          backgroundColorTop = 'rgba(215, 54, 60, 0.4)'
          backgroundColorBottom = 'rgba(255, 221, 220, 0.4)'
          backdropColor = 'rgba(255, 221, 220, 0.5)'
          break
        case 'ok':
          borderColor = 'rgba(255, 168, 0, 1)'
          backgroundColor = 'rgba(255, 244, 213, 0.5)'
          backgroundColorTop = 'rgba(255, 168, 0, 0.4)'
          backgroundColorBottom = 'rgba(255, 243, 208, 0.4)'
          backdropColor = 'rgba(254, 247, 227, 0.5)'

          break
        default:
          borderColor = 'rgba(24, 176, 112, 1)'
          backgroundColor = 'rgba(171, 230, 210, 0.5)'
          backgroundColorTop = 'rgba(24, 176, 112, 0.4)'
          backgroundColorBottom = 'rgba(211, 232, 229, 0.4)'
          backdropColor = 'rgba(230, 254, 250, 0.5)'
      }
      return { borderColor, backgroundColor, backdropColor, backgroundColorTop, backgroundColorBottom }
    },
    parsedChartData() {
      return {
        labels: Array.from({ length: this.actual.length }, (_, i) => i + 1),
        datasets: [
          {
            label: 'Actual',
            backgroundColor: this.chartColors.backgroundColor,
            borderColor: this.chartColors.borderColor,
            data: this.actual,
            fill: true,
            pointRadius: 0,
            lineTension: 0.6,
            borderWidth: 1,
          },
          {
            label: 'Forecast',
            backgroundColor: 'rgba(240, 241, 243, 0.4)',
            borderWidth: 1,
            borderDash: [10, 10],
            borderColor: '#AFAFAF',
            data: this.forecast,
            fill: true,
            pointRadius: 0,
            lineTension: 0.6,
          },
          {
            label: 'Area Above Line',
            fill: true,
            pointRadius: 0,
            backgroundColor: this.chartColors.backdropColor,
            borderColor: 'rgba(0, 0, 0, 0)',
          },
        ],
      }
    },
  },
  mounted() {
    this.addGradient()
  },
  methods: {
    addGradient() {
      const canvas = document.getElementById('line-chart')
      const ctx = canvas.getContext('2d')

      var gradient = ctx.createLinearGradient(canvas.width, canvas.height / 5, canvas.width, canvas.height / 1.5)
      gradient.addColorStop(0, this.chartColors.backgroundColorTop)
      gradient.addColorStop(1, this.chartColors.backgroundColorBottom)

      this.$refs.lineChart.chartData.datasets[0].backgroundColor = gradient
      this.$refs.lineChart._data._chart.update()
    },
  },
}
</script>
