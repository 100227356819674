<template>
  <!--  Campaigns Table-->
  <div class="w-full rounded-2xl">
    <div v-if="isModuleLoading">
      <brightbid-loader size="120">Loading bidding updates</brightbid-loader>
    </div>
    <div
      v-else-if="biddingUpdates.length > 0"
      class="border rounded-2xl divide-y"
    >
      <table class="table-fixed w-full">
        <thead class="text-bb-text-secondary text-sm font-medium">
          <tr>
            <th class="w-1/4 p-4 text-left">Description</th>
            <th class="w-5/12 p-4 text-left">Campaign</th>
            <th class="w-1/6 p-4 text-left">Segment</th>
            <th class="w-1/6 p-4 text-right">Timestamp</th>
          </tr>
        </thead>
      </table>
      <div class="table-body-container overflow-y-auto base-scrollbar text-sm">
        <table class="table-fixed w-full">
          <tbody>
            <tr
              v-for="(item, index) in biddingUpdates"
              :key="index"
            >
              <td class="w-1/4 p-4">{{ rowDescription(item) }}</td>
              <td
                class="max-w-60 p-4 truncate"
                :title="rowCampaignDetail(item)"
              >
                {{ rowCampaignDetail(item) }}
              </td>
              <td class="w-1/6 p-4">
                <div class="flex flex-wrap items-center">
                  <span
                    class="rounded-2xl px-2 py-1 mr-1 font-bold"
                    :class="getStyle(item.criterion)"
                    >{{ item.criterion }}</span
                  >
                  <span>{{ item.subcriterion }}</span>
                </div>
              </td>
              <td class="w-1/6 p-4 text-right">{{ formattedDate(item.created_at) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import BrightbidLoader from '@/components/loader/BrightbidLoader'
import dayjs from 'dayjs'

export default {
  name: 'BiddingUpdates',
  components: {
    BrightbidLoader,
  },
  props: {
    biddingUpdates: {
      type: Array,
      required: true,
      default: () => [],
    },
    isModuleLoading: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    getStyle(criterion) {
      let styleClass = ''
      switch (criterion) {
        case 'Age Range':
          styleClass = 'text-bb-custom-purple bg-bb-dull-purple'
          break
        case 'Gender':
          styleClass = 'text-bb-fuchsia-pink bg-bb-blush-pink'
          break
        case 'Device':
          styleClass = 'text-bb-mustard bg-bb-dull-yellow'
          break
        case 'Income Range':
          styleClass = 'bg-bb-success-background text-green-700'
          break
        case 'Day Of Week':
          styleClass = 'bg-bb-decorative-cyan text-bb-blue-1'
          break
      }
      return styleClass
    },
    rowDescription(item) {
      let bidModifier = item.bid_modifier
      let formattedModifier = Math.round(bidModifier * 100) / 100
      formattedModifier = formattedModifier % 1 === 0 ? formattedModifier.toString() : formattedModifier.toFixed(2)

      if (bidModifier > 0) {
        return `Bid modifier updated to +${formattedModifier}%`
      } else {
        return `Bid modifier updated to ${formattedModifier}%`
      }
    },
    rowCampaignDetail(item) {
      return item.campaign_name + ' ' + item.ad_group_name
    },
    formattedDate(date) {
      return dayjs(date).format('YYYY-MM-DD HH:mm:ss')
    },
  },
}
</script>

<style>
.table-body-container {
  height: 240px;
}
</style>
