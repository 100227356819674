<template>
  <div class="bg-white border border-neutral-100 rounded-2xl shadow-bb-shadow px-6 py-4 flex flex-col gap-3">
    <div class="flex flex-col gap-3">
      <div class="flex flex-row gap-2">
        <div class="bg-neutral-0 rounded-lg p-1 my-auto">
          <component
            :is="iconComponent"
            class="h-5 w-5 m-1 text-bb-brand-purple"
          />
        </div>
        <p class="h5 my-auto capitalize">
          {{ label }}
        </p>
      </div>
      <div class="flex flex-row flex-wrap gap-3">
        <p class="pNumMassive">
          {{ formatNumberWithSpaces(metric.current) ?? '-'
          }}<span
            v-if="label === 'cost' || label === 'average CPC'"
            class="my-auto ml-4"
            >{{ currency }}</span
          >
          <span
            v-if="label === 'conversion rate' || label === 'CTR'"
            class="my-auto ml-1"
            >%</span
          >
        </p>
        <!-- if cost or cpc, display currency -->
        <div class="flex flex-row gap-1">
          <ic-arrow
            :size="12"
            class="transform my-auto"
            :class="{
              '-rotate-90 text-success':
                (label === 'cost' || label === 'average CPC') && metric.variation_percentage <= 0,
              'rotate-90 text-bb-error':
                (label === 'cost' || label === 'average CPC') && metric.variation_percentage > 0,
              'rotate-90 text-success': label !== 'cost' && label !== 'average CPC' && metric.variation_percentage > 0,
              '-rotate-90 text-bb-error':
                label !== 'cost' && label !== 'average CPC' && metric.variation_percentage <= 0,
            }"
          ></ic-arrow>
          <p
            class="pNumLarge text-base my-auto"
            :class="{
              'text-bb-error':
                label === 'cost' || label === 'average CPC'
                  ? metric.variation_percentage > 0
                  : metric.variation_percentage <= 0,
              'text-success':
                label === 'cost' || label === 'average CPC'
                  ? metric.variation_percentage <= 0
                  : metric.variation_percentage > 0,
            }"
          >
            {{ metric.variation_percentage > 0 ? '+' : '' }}{{ metric.variation_percentage ?? '-' }}%
          </p>
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-2">
      <p class="text-bb-text-secondary">
        Previous:
        <span class="font-bold"
          >{{ metric.compare ? formatNumberWithSpaces(metric.compare) : '-'
          }}<span
            v-if="label === 'cost' || label === 'average CPC'"
            class="my-auto ml-1"
            >{{ currency }}</span
          >
          <span
            v-if="label === 'conversion rate' || label === 'CTR'"
            class="my-auto ml-1"
            >%</span
          ></span
        >
      </p>
      <p class="text-bb-text-secondary">
        Change:
        <span
          :class="{
            'text-bb-error':
              label === 'cost' || label === 'average CPC' ? metric.variation_value >= 0 : metric.variation_value < 0,
            'text-success':
              label === 'cost' || label === 'average CPC' ? metric.variation_value < 0 : metric.variation_value >= 0,
          }"
          class="font-bold pNum"
        >
          {{ metric.variation_value > 0 ? '+' : '' }}{{ formatNumberWithSpaces(metric.variation_value) ?? '-' }}
          <span
            v-if="label === 'cost' || label === 'average CPC'"
            class="my-auto"
            >{{ currency }}</span
          >
          <span
            v-if="label === 'conversion rate' || label === 'CTR'"
            class="my-auto"
            >%</span
          >
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import IcEye from '@/components/icon/ic-eye.vue'
import IcDollar from '@/components/icon/ic-dollar.vue'
import IcDollarCircle from '@/components/icon/ic-dollar-circle.vue'
import IcCurrencyExchange from '@/components/icon/ic-currency-exchange.vue'
import IcClicks from '@/components/icon/ic-clicks.vue'
import IcCtr from '@/components/icon/ic-ctr.vue'
import IcAverageCpc from '@/components/icon/ic-average-cpc.vue'
import IcArrow from '@/components/icon/brightbid/ic-arrow.vue'

export default {
  name: 'MetricWidget',
  components: {
    IcEye,
    IcDollar,
    IcDollarCircle,
    IcCurrencyExchange,
    IcClicks,
    IcCtr,
    IcAverageCpc,
    IcArrow,
  },
  props: {
    iconComponent: {
      type: [String, Object],
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    currency: {
      type: String,
      required: false,
    },
    metric: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatNumberWithSpaces(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
  },
}
</script>
