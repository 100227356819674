<template>
  <div class="mb-8">
    <div class="flex flex-col gap-2">
      <div class="flex flex-row gap-2 pt-3">
        <ic-sparkle-key
          :size="26"
          class="text-bb-brand-purple mt-1"
        />
        <p class="h3 font-bold">Keywords Performance</p>
      </div>
      <p class="ml-8 pb-5 max-w-3xl">Here's a snapshot of your top-performing keywords</p>
    </div>
    <div class="flex flex-col lg:grid lg:grid-cols-2 gap-4 mb-8">
      <TopKwCard
        v-for="keyword in topKeywordsLoading ? [1, 2, 3, 4] : topKeywords"
        :key="topKeywordsLoading ? keyword : keyword.id"
        :loading="topKeywordsLoading"
        :keyword="topKeywordsLoading ? null : keyword"
      />
    </div>
    <div
      v-if="topKeywords"
      class="gradient-border"
    >
      <div class="py-8 px-16 flex flex-col gap-4 md:gap-0 md:flex-row justify-between items-center">
        <div class="flex flex-row gap-4">
          <img
            src="/content/images/brightbid/bright-bid-logo-white2.svg"
            alt=""
            class="h-16"
          />
          <div class="flex flex-col text-white">
            <p class="h4">Boost your conversion rates with fresh Keyword Recommendations!</p>
            <p class="h5 font-normal">Receive weekly recommendations tailored for you.</p>
          </div>
        </div>
        <merge-button-round
          class="my-auto min-w-xxs"
          :disabled="topKeywordsLoading"
          button-type="secondary"
          @click="$router.push({ name: 'site:search:keyword:suggestions' })"
        >
          Go to Keyword Suggestion
          <template #right-icon>
            <ic-chevron-right
              :size="16"
              class="my-auto"
            ></ic-chevron-right>
          </template>
        </merge-button-round>
      </div>
    </div>
    <div
      v-else
      class="bg-neutral-0 rounded-2xl px-20 py-6"
    >
      <div class="flex flex-row justify-center gap-6 max-w-90 mx-auto">
        <img
          src="/content/images/brightbid/sad-bot.svg"
          alt="welcome-robot"
        />
        <p class="text-bb-text-secondary text-left">
          Unfortunately, we have no data available for the selected date range. Please try a different range or check
          back later.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import TopKwCard from '@/components/card/TopKwCard.vue'
import dayjs from 'dayjs'
import IcSparkleKey from '@/components/icon/brightbid/IcSparkleKey.vue'
import IcChevronRight from 'vue-material-design-icons/ChevronRight'

export default {
  name: 'TopKwWidget',
  components: {
    TopKwCard,
    IcSparkleKey,
    IcChevronRight,
  },
  props: {
    targetType: {
      type: String,
      required: true,
    },
  },
  watch: {
    async selectedSite() {
      await this.fetchTopKeywords({
        siteId: this.selectedSite.value,
        start_date: this.startDate,
        end_date: this.endDate,
        target_type: this.targetType,
      })
    },
  },
  data() {
    return {
      endDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
      startDate: dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
    }
  },
  computed: {
    ...mapState('site', ['selectedSite']),
    ...mapGetters('keywords', ['topKeywords', 'topKeywordsLoading', 'topKeywordsError']),
  },
  methods: {
    ...mapActions('keywords', ['fetchTopKeywords']),
  },
  async created() {
    await this.fetchTopKeywords({
      siteId: this.selectedSite.value,
      start_date: this.startDate,
      end_date: this.endDate,
      target_type: this.targetType,
    })
  },
}
</script>

<style scoped>
.gradient-border {
  position: relative;
  border-radius: 16px;
  padding: 1px;
}

.gradient-border > div {
  border-radius: 16px;
  background: linear-gradient(145.33deg, #a78bfa -12.2%, #6366fa 88.33%);
}
</style>
