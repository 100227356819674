<template>
  <div
    class="bg-white shadow-bb-shadow py-6 px-4 rounded-2xl flex flex-col gap-4 md:gap-6 lg:gap-4 xl:gap-6 md:flex-row lg:flex-col bbxl:flex-row w-full justify-between"
    v-if="loading && !keyword"
  >
    <div class="flex flex-row gap-2 items-center my-auto animate-pulse">
      <div class="bg-gray-300 rounded-full h-6 w-6"></div>
      <div class="bg-gray-300 rounded w-34 h-6"></div>
    </div>

    <div class="flex flex-row justify-evenly w-full animate-pulse">
      <div class="flex flex-col gap-2">
        <div class="bg-gray-300 rounded w-8 h-3"></div>
        <div class="bg-gray-300 rounded w-20 h-6"></div>
      </div>
      <span class="min-h-full bg-neutral-100 pl-px"></span>
      <div class="flex flex-col gap-2">
        <div class="bg-gray-300 rounded w-8 h-3"></div>
        <div class="bg-gray-300 rounded w-20 h-6"></div>
      </div>
      <span class="min-h-full bg-neutral-100 pl-px"></span>
      <div class="flex flex-col gap-2">
        <div class="bg-gray-300 rounded w-8 h-3"></div>
        <div class="bg-gray-300 rounded w-20 h-6"></div>
      </div>
      <span class="min-h-full bg-neutral-100 pl-px"></span>
      <div class="flex flex-col gap-2">
        <div class="bg-gray-300 rounded w-8 h-3"></div>
        <div class="bg-gray-300 rounded w-20 h-6"></div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="shadow-bb-shadow border py-6 px-4 rounded-lg flex flex-wrap gap-4 bg-white"
  >
    <div class="flex flex-row gap-2 items-center my-auto">
      <p
        v-if="keyword.id in keywordClasses"
        :class="`px-2 rounded-full font-sans font-bold text-center ${keywordClasses[keyword.id].textClass} ${
          keywordClasses[keyword.id].bgClass
        }`"
      >
        {{ keyword.id }}
      </p>
      <p class="h5 truncate pr-1 mr-2">
        {{ keyword.keyword_text }}
        <span class="text-bb-text-secondary p3">{{ matchType }}</span>
      </p>
    </div>

    <div class="flex flex-row justify-evenly w-full">
      <div>
        <p class="p3 text-bb-text-secondary font-normal">Clicks</p>
        <p class="p1 font-medium">{{ keyword.metrics_clicks }}</p>
      </div>
      <span class="min-h-full bg-neutral-100 pl-px"></span>
      <div>
        <p class="p3 text-bb-text-secondary font-normal">Conversions</p>
        <p class="p1 font-medium">{{ readableMetric(keyword.metrics_conversions) }}</p>
      </div>
      <span class="min-h-full bg-neutral-100 pl-px"></span>
      <div>
        <p class="p3 text-bb-text-secondary font-normal">Cost</p>
        <p class="p1 font-medium">{{ readableMetric(keyword.metrics_cost) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopKwCard',
  components: {},
  props: {
    keyword: {
      type: Object,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      keywordClasses: {
        1: { textClass: 'text-bb-decorative-green', bgClass: 'bg-bb-green-50' },
        2: { textClass: 'text-bb-purple-300', bgClass: 'bg-bb-secondary-purple' },
        3: { textClass: 'text-bb-cyan', bgClass: 'bg-bb-decorative-cyan' },
        4: { textClass: 'text-bb-warning', bgClass: 'bg-bb-warning-100' },
      },
    }
  },
  computed: {
    matchType() {
      const matchType = this.keyword.keyword_matchType.toLowerCase()
      const capitalizedMatchType = matchType.charAt(0).toUpperCase() + matchType.slice(1)
      return `(${capitalizedMatchType} Match)`
    },
  },
  watch: {},
  mounted() {},
  methods: {
    readableMetric(metric) {
      return Math.round(metric).toLocaleString()
    },
  },
}
</script>
