<script>
import { Line, mixins } from 'vue-chartjs'

const { reactiveProp } = mixins

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: {
    month: {
      type: Number,
      required: true,
    },
    currency: {
      type: String,
      required: false,
      default: null,
    },
    targetType: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      tooltipText: '',
      // Default chart options
      chartOptions: {
        legend: {
          display: false, // Hide the labels
        },
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: -10,
          },
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                display: false, // Keep horizontal lines disabled
              },
              ticks: {
                beginAtZero: true,
                display: false, // Hide the tick labels
                max: this.getMaxYValue(), // Set max value dynamically
              },
            },
            {
              position: 'right', // Add this yAxis at the right
              gridLines: {
                display: false, // Enable grid lines
                borderColor: '#E0E0E0',
                borderDash: [10, 5], // Creates dotted lines
              },
              ticks: {
                display: false, // Hide the tick labels
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: true, // Enable vertical lines
                drawBorder: true,
                borderColor: '#E0E0E0',
                drawOnChartArea: true,
                drawTicks: true,
                borderDash: [10, 5], // Creates dotted lines
              },
              ticks: {
                display: false, // Show the tick labels
                padding: 10,
                callback: function (value, index, values) {
                  const indices = [
                    0,
                    Math.floor(values.length / 4),
                    Math.floor(values.length / 2),
                    Math.floor((values.length * 3) / 4),
                    values.length - 1,
                  ]

                  return indices.includes(index) ? value : null
                },
              },
            },
          ],
        },
        elements: {
          line: {
            tension: 0.4, // Smoother lines
          },
          point: {
            radius: 0, // Removes the dots
          },
        },
        tooltips: {
          enabled: true,
          mode: 'nearest',
          intersect: false,
          displayColors: false,
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          titleFontColor: '#3E4552',
          titleFont: { weight: 'lighter' },
          custom: tooltipModel => {
            tooltipModel.xPadding = 12
            tooltipModel.yPadding = 12

            tooltipModel.titleMarginBottom = 18
            tooltipModel._titleFontStyle = 'normal'

            tooltipModel.footerFontColor = '#3E4552'
            tooltipModel.footerFontSize = 14

            tooltipModel.height = 70
            tooltipModel.y -= 20
            tooltipModel.width = this.calculateTextWidth(this.tooltipText, 'bold 14px Arial') + 24
          },
          callbacks: {
            title: tooltipItem => {
              if (tooltipItem.length === 0) {
                return null
              }

              const monthName = this.monthNames[this.month - 1]
              return `${tooltipItem[0].index + 1} ${monthName}`
            },
            label: () => null,
            footer: tooltipItem => {
              if (tooltipItem.length === 0) {
                return null
              }
              const value = tooltipItem[0].value
              const formatedValue = value ? parseFloat(parseFloat(value).toFixed(0)) : 0
              const dataText = this.currency ? `${formatedValue} ${this.currency}` : `${formatedValue} %`
              const tooltipText = `Daily ${this.targetType}           ${dataText}`
              this.tooltipText = tooltipText

              return tooltipText
            },
          },
          filter: function (tooltipItem) {
            return tooltipItem.datasetIndex === 0
          },
        },
      },
    }
  },
  mounted() {
    // Adjust the order of datasets
    const datasets = this.chartData.datasets
    this.chartData.datasets = [datasets[0], datasets[2], datasets[1]]

    this.chartData.datasets.forEach(dataset => {
      if (dataset.label === 'Area Above Line') {
        dataset.data = this.getAreaAboveLineData()
      }
      // Set the tension for smoother lines
      dataset.tension = 0.4
    })
    this.renderChart(this.chartData, this.options || this.chartOptions)
  },
  methods: {
    getMaxYValue() {
      let max = 1 // > 0 default value for visuals on flatlines
      this.chartData.datasets.forEach(dataset => {
        if (dataset.label !== 'Area Above Line') {
          const datasetMax = Math.max(...dataset.data)
          if (datasetMax > max) {
            max = datasetMax
          }
        }
      })
      return max * 1.3 // Add 30%
    },
    getAreaAboveLineData() {
      const maxYValue = this.getMaxYValue()
      let actual = []
      this.chartData.datasets.forEach(dataset => {
        if (dataset.label === 'Actual') {
          actual = dataset.data
        }
      })
      return actual.map(value => (value === null ? null : maxYValue))
    },
    calculateTextWidth(text, font) {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      context.font = font
      return context.measureText(text).width
    },
  },
}
</script>
