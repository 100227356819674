<template>
  <div class="flex flex-col gap-8">
    <div class="p-6 border border-neutral-100 rounded-2xl shadow-bb-shadow bg-white">
      <div class="flex flex-col">
        <div class="h3 mb-4 flex flex-row">
          <div class="m-2 rounded-lg my-auto bg-neutral-0 p-2">
            <ic-dollar-circle class="text-bb-brand-purple" />
          </div>
          <p class="h5 my-2 font-bold">Current Spend vs Target Spend</p>
        </div>
        <div v-if="!chartDataLoading">
          <!-- Check if site_metric_target is null or spend is 0 -->
          <div
            v-if="siteMetrics.site_metric_target === null || siteMetrics.site_metric_target.spend === 0"
            class="bg-neutral-0 rounded-lg px-20 py-6"
          >
            <div class="flex flex-row justify-center gap-6 items-center">
              <img
                src="/content/images/brightbid/bid-bot.svg"
                alt="welcome-robot"
              />
              <p class="text-left max-w-80">
                You seem to be missing the <span class="font-bold">Target Spend</span> for your account. Consider adding
                it to begin monitoring your monthly spend.
              </p>
              <div class="flex items-center justify-between">
                <div class="space-y-2">
                  <div class="flex flex-row gap-2">
                    <template>
                      <ic-dollar-circle
                        :size="24"
                        class="pr-1 text-bb-disabled-buttons"
                      />
                    </template>
                    <div class="text-bb-text-secondary font-medium">Spend Target</div>
                  </div>
                  <text-input
                    v-model="spendTarget"
                    class="w-fit bb-text-secondary-gray pNumLarge"
                    input-type="text"
                    input-name="targetGoalSpend"
                    input-id="targetGoalSpend"
                    placeholder="-"
                    @keydown.enter="updateSiteMetrics('spend', spendTarget)"
                  >
                    <template #suffix>
                      <div class="absolute right-0 top-0 z-10 h-10 p-2 text-bb-disabled-gray">
                        {{ siteMetrics.currency }}
                      </div>
                    </template>
                  </text-input>
                  <p class="text-bb-text-secondary p3">Press Enter to save.</p>
                </div>
              </div>
            </div>
          </div>
          <!-- Show Spend Data if Available -->
          <div
            v-else-if="chartData && siteMetrics.site_metric_target.spend > 0"
            class="leading-normal"
          >
            <div class="flex flex-wrap md:flex-no-wrap flex-row w-full justify-between gap-8">
              <BbLineChart
                class="order-1 md:order-2"
                :actual="chartData.actual.spend"
                :forecast="chartData.forecast.spend"
                :month="month"
                :threshold="threshold.spend.status"
                :currency="siteMetrics.currency"
                target-type="Spend"
              ></BbLineChart>
              <div class="flex flex-col gap-y-2 text-left md:text-right min-w-40 whitespace-no-wrap order-2 md:order-1">
                <p
                  class="font-bold text-xs rounded-full px-3 py-0.5 ml-0 md:ml-auto md:mr-0 md:text-right mx-auto"
                  :class="{
                    'text-bb-green-accepted bg-bb-green-50': threshold.spend.status === 'good',
                    'text-bb-warning-200 bg-bb-yellow-100': threshold.spend.status === 'ok',
                    'text-bb-error bg-bb-red-50': threshold.spend.status === 'bad',
                  }"
                >
                  {{ threshold.spend.message }}
                </p>
                <p class="text-bb-default font-medium">Current Spend</p>
                <p class="text-2xl leading-normal font-bold text-bb-text-headers">
                  {{ formatNumberWithSpaces(summary.spend.actual) }}
                  <span class="text-base uppercase font-semibold">{{ siteMetrics.currency }}</span>
                </p>
                <p class="text-bb-neutral-gray font-medium">
                  {{ formatNumberWithSpaces((summary.spend.actual / summary.spend.target) * 100) }}% of target
                </p>
              </div>
              <div class="flex flex-col gap-3 whitespace-no-wrap min-w-50 order-3">
                <div class="flex flex-row gap-2 items-start">
                  <ic-target class="text-bb-disabled-buttons" />
                  <div class="flex flex-col">
                    <p class="text-sm text-bb-neutral-gray font-medium">Target Spend</p>
                    <div class="font-bold text-base text-bb-text-headers">
                      {{ formatNumberWithSpaces(summary.spend.target) }}
                      <span class="text-sm uppercase font-semibold">{{ siteMetrics.currency }}</span>
                    </div>
                  </div>
                </div>
                <div class="flex flex-row gap-2 items-start">
                  <ic-trend-target class="text-bb-disabled-buttons" />
                  <div class="flex flex-col">
                    <p class="text-sm text-bb-neutral-gray font-medium">Monthly Forecast</p>
                    <div class="font-bold text-base text-bb-text-headers">
                      {{ formatNumberWithSpaces(summary.spend.forecast) }}
                      <span class="text-sm uppercase font-semibold">{{ siteMetrics.currency }}</span>
                    </div>
                    <p
                      class="text-sm font-medium"
                      :class="{
                        'text-bb-green-accepted': threshold.spend.status === 'good',
                        'text-bb-error': threshold.spend.status === 'bad',
                        'text-bb-mustard': threshold.spend.status === 'ok',
                      }"
                    >
                      {{ formatNumberWithSpaces((summary.spend.forecast / summary.spend.target) * 100) }}% of target
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- No Data Available -->
          <div
            v-else
            class="bg-neutral-0 rounded-lg px-20 py-6"
          >
            <div class="flex flex-row justify-center gap-6 max-w-90 mx-auto">
              <img
                src="/content/images/brightbid/sad-bot.svg"
                alt="welcome-robot"
              />
              <p class="text-bb-text-secondary text-left">
                Unfortunately, we have no data available for the selected date range. Please try a different range or
                check back later.
              </p>
            </div>
          </div>
        </div>
        <!-- Loading State -->
        <div v-else>
          <BrightbidLoader width="120"></BrightbidLoader>
        </div>
      </div>
    </div>
    <!-- Second Block (similar logic for CPA/ROAS) -->
    <div class="p-6 border border-neutral-100 rounded-2xl shadow-bb-shadow bg-white">
      <div class="flex flex-col">
        <div class="h3 mb-4 flex flex-row">
          <div class="m-2 rounded-lg my-auto bg-neutral-0 p-2">
            <ic-target-cpa class="text-bb-brand-purple" />
          </div>
          <p class="h5 my-auto font-bold">
            <span
              v-if="
                siteMetrics.site_metric_target &&
                (siteMetrics.site_metric_target.roas > 0 || siteMetrics.site_metric_target.cpa > 0)
              "
            >
              Current {{ cpaOrRoas.toUpperCase() }} vs Target {{ cpaOrRoas.toUpperCase() }}
            </span>
            <span v-else>
              Current {{ selectedTarget.value.toUpperCase() }} vs Target {{ selectedTarget.value.toUpperCase() }}
            </span>
          </p>
        </div>
        <div v-if="!chartDataLoading">
          <!-- Check if site_metric_target is null or ROAS/CPA is 0 -->
          <div
            v-if="
              siteMetrics.site_metric_target === null ||
              (siteMetrics.site_metric_target.roas === 0 && siteMetrics.site_metric_target.cpa === 0)
            "
            class="bg-neutral-0 rounded-lg px-20 py-6"
          >
            <div class="flex flex-row justify-center gap-6 items-center">
              <img
                src="/content/images/brightbid/bid-bot.svg"
                alt="welcome-robot"
              />
              <p class="text-left max-w-80">
                You seem to be missing the <span class="font-bold">Target CPA/ROAS</span> for your account. Consider
                adding it to begin monitoring your CPA/ROAS.
              </p>
              <div class="flex flex-col gap-2">
                <div class="space-y-4 z-10">
                  <select-dropdown
                    class="text-bb-text-secondary"
                    :selection-value="selectedTarget"
                    :title="''"
                    :options="targetOptions"
                    @select-item="changeTargetGoal"
                  >
                    <template #icon>
                      <ic-target-cpa
                        v-if="selectedTarget.value == 'cpa'"
                        :size="24"
                        class="pr-1 text-bb-disabled-buttons"
                      />
                      <ic-target-roi
                        v-if="selectedTarget.value == 'roas'"
                        :size="24"
                        class="pr-1 text-bb-disabled-buttons"
                      />
                    </template>
                  </select-dropdown>
                </div>
                <div class="relative z-0">
                  <text-input
                    v-if="selectedTarget.value == 'cpa'"
                    v-model="cpaTarget"
                    class="w-fit bb-text-secondary-gray pNumLarge"
                    input-type="text"
                    input-name="targetGoalCpa"
                    input-id="targetGoalCpa"
                    placeholder="-"
                    @keydown.enter="updateSiteMetrics('cpa', cpaTarget)"
                  >
                    <template #suffix>
                      <div class="absolute right-0 top-0 z-10 h-10 p-2 text-bb-disabled-gray">
                        {{ siteMetrics.currency }}
                      </div>
                    </template>
                  </text-input>
                  <text-input
                    v-else
                    v-model="roasTarget"
                    class="w-fit bb-text-secondary-gray pNumLarge"
                    input-type="text"
                    input-name="targetGoalRoas"
                    input-id="targetGoalRoas"
                    placeholder="-"
                    @keydown.enter="updateSiteMetrics('roas', roasTarget)"
                  >
                    <template #suffix>
                      <div class="absolute right-0 top-0 z-10 h-10 p-2 text-bb-disabled-gray">%</div>
                    </template>
                  </text-input>
                </div>
                <p class="text-bb-text-secondary p3">Press Enter to save.</p>
              </div>
            </div>
          </div>
          <!-- Show CPA/ROAS Data if Available -->
          <div
            v-else-if="chartData && summary[cpaOrRoas].target > 0"
            class="leading-normal"
          >
            <div class="flex flex-wrap md:flex-no-wrap flex-row w-full justify-between gap-8">
              <BbLineChart
                class="order-1 md:order-2"
                :actual="chartData.actual[cpaOrRoas]"
                :forecast="chartData.forecast[cpaOrRoas]"
                :month="month"
                :threshold="threshold[cpaOrRoas].status"
                :currency="cpaOrRoas === 'cpa' ? siteMetrics.currency : null"
                :target-type="cpaOrRoas === 'cpa' ? 'CPA' : 'ROAS'"
              ></BbLineChart>
              <div class="flex flex-col gap-y-2 text-left md:text-right min-w-40 whitespace-no-wrap order-2 md:order-1">
                <p
                  class="font-bold text-xs rounded-full px-3 py-0.5 ml-0 md:ml-auto md:mr-0 md:text-right mx-auto"
                  :class="{
                    'text-bb-green-accepted bg-bb-green-50': threshold[cpaOrRoas].status === 'good',
                    'text-bb-warning-200 bg-bb-yellow-100': threshold[cpaOrRoas].status === 'ok',
                    'text-bb-error bg-bb-red-50': threshold[cpaOrRoas].status === 'bad',
                  }"
                >
                  {{ threshold[cpaOrRoas].message }}
                </p>
                <p class="text-bb-default font-medium">Current {{ cpaOrRoas.toUpperCase() }}</p>
                <p class="text-2xl leading-normal font-bold text-bb-text-headers">
                  {{ formatNumberWithSpaces(summary[cpaOrRoas].actual) }}
                  <span
                    class="font-semibold"
                    :class="{ 'text-base': valueSuffix !== '%' }"
                  >
                    {{ valueSuffix }}
                  </span>
                </p>
                <p class="text-bb-neutral-gray font-medium">
                  {{ formatNumberWithSpaces((summary[cpaOrRoas].actual / summary[cpaOrRoas].target) * 100) }}% of target
                </p>
              </div>
              <div class="flex flex-col gap-3 whitespace-no-wrap min-w-50 order-3">
                <div class="flex flex-row gap-2 items-start">
                  <ic-target class="text-bb-disabled-buttons" />
                  <div class="flex flex-col">
                    <p class="text-sm text-bb-neutral-gray font-medium">Target {{ cpaOrRoas.toUpperCase() }}</p>
                    <div class="font-bold text-base text-bb-text-headers">
                      <span>{{ formatNumberWithSpaces(summary[cpaOrRoas].target) }}</span>
                      <span
                        class="font-semibold"
                        :class="valueSuffix !== '%' ? 'text-sm' : 'text-base'"
                        >{{ valueSuffix }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="flex flex-row gap-2 items-start">
                  <ic-trend-target class="text-bb-disabled-buttons" />
                  <div class="flex flex-col">
                    <p class="text-sm text-bb-neutral-gray font-medium">Monthly Forecast</p>
                    <div class="font-bold text-base text-bb-text-headers">
                      <span>{{ formatNumberWithSpaces(summary[cpaOrRoas].forecast) }}</span>
                      <span
                        class="font-semibold"
                        :class="valueSuffix !== '%' ? 'text-sm' : 'text-base'"
                        >{{ valueSuffix }}</span
                      >
                    </div>
                    <p
                      class="text-sm font-medium"
                      :class="{
                        'text-bb-green-accepted': threshold[cpaOrRoas].status === 'good',
                        'text-bb-error': threshold[cpaOrRoas].status === 'bad',
                        'text-bb-mustard': threshold[cpaOrRoas].status === 'ok',
                      }"
                    >
                      {{ formatNumberWithSpaces((summary[cpaOrRoas].forecast / summary[cpaOrRoas].target) * 100) }}% of
                      target
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- No Data Available -->
          <div
            v-else
            class="bg-neutral-0 rounded-lg px-20 py-6"
          >
            <div class="flex flex-row justify-center gap-6 max-w-90 mx-auto">
              <img
                src="/content/images/brightbid/sad-bot.svg"
                alt="welcome-robot"
              />
              <p class="text-bb-text-secondary text-left">
                Unfortunately, we have no data available for the selected date range. Please try a different range or
                check back later.
              </p>
            </div>
          </div>
        </div>
        <!-- Loading State -->
        <div v-else>
          <BrightbidLoader width="120"></BrightbidLoader>
        </div>
      </div>
    </div>
    <div class="flex flex-row text-bb-text-default ml-8">
      Go to
      <router-link
        :to="{
          name: 'site:settings:site',
        }"
        class="text-bb-brand-purple font-bold mx-1"
      >
        <span class="flex flex-row items-center gap-1">
          <ic-cog :size="16" />
          Settings
        </span>
      </router-link>
      to edit Targets
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import BrightbidLoader from '@/components/loader/BrightbidLoader'
import { mapGetters, mapActions } from 'vuex'
import IcDollarCircle from '@/components/icon/ic-dollar-circle'
import IcTarget from 'vue-material-design-icons/Bullseye'
import IcTargetCpa from '@/components/icon/ic-target-cpa.vue'
import BbLineChart from '../chart/bbcharts/charts/BbLineChart.vue'
import IcCog from 'vue-material-design-icons/Cog'
import TextInput from '@/components/input/brightbid/TextInput'
import Toast from '@/components/shared/Toast'
import SelectDropdown from '@/components/input/brightbid/SelectDropdown'
import IcTargetRoi from '@/components/icon/ic-target-roi.vue'
import IcTrendTarget from '@/components/icon/ic-trend-target.vue'

const TARGET_OPTIONS = [
  { label: 'CPA Target', value: 'cpa' },
  { label: 'ROAS Target', value: 'roas' },
]

export default {
  name: 'ChartWidget',
  components: {
    BrightbidLoader,
    IcDollarCircle,
    IcTargetCpa,
    BbLineChart,
    IcTarget,
    IcCog,
    TextInput,
    SelectDropdown,
    IcTargetRoi,
    IcTrendTarget,
  },
  props: {
    siteId: {
      type: Number,
      required: true,
    },
    siteMetrics: {
      type: Object,
      required: true,
    },
    month: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dateRange: this.getDateRange(this.month),
      spendTarget: null,
      cpaTarget: null,
      roasTarget: null,
      targetOptions: TARGET_OPTIONS,
      selectedTarget: TARGET_OPTIONS[0],
    }
  },
  watch: {
    async siteId() {
      this.fetchData()
    },
    async month() {
      this.dateRange = this.getDateRange(this.month)
      this.fetchData()
    },
  },
  computed: {
    ...mapGetters('dashboardCharts', ['chartData', 'chartDataLoading', 'chartDataError']),
    summary() {
      return this.chartData ? this.chartData.summary : {}
    },
    threshold() {
      return {
        spend: this.calculateThreshold((this.summary.spend.forecast / this.summary.spend.target) * 100, 'spend'),
        cpa: this.calculateThreshold((this.summary.cpa.forecast / this.summary.cpa.target) * 100, 'cpa'),
        roas: this.calculateThreshold((this.summary.roas.forecast / this.summary.roas.target) * 100, 'roas'),
      }
    },
    cpaOrRoas() {
      return this.siteMetrics.site_metric_target.roas ? 'roas' : 'cpa'
    },
    valueSuffix() {
      return this.cpaOrRoas === 'roas' ? '%' : ` ${this.siteMetrics.currency}/Conv`
    },
  },
  async created() {
    this.fetchData()
  },
  methods: {
    ...mapActions('dashboardCharts', ['fetchChartData']),
    getDateRange(month) {
      const startOfMonth = dayjs()
        .month(month - 1)
        .startOf('month')
        .format('YYYY-MM-DD')
      const endOfMonth = dayjs()
        .month(month - 1)
        .endOf('month')
        .format('YYYY-MM-DD')
      return { start: startOfMonth, end: endOfMonth }
    },
    calculateThreshold(variation_percentage, metric) {
      let status = 'good'
      let message = 'On target'

      if (metric === 'roas') {
        if (variation_percentage <= 85) {
          status = 'bad'
          message = 'Under target'
        } else if (variation_percentage <= 95) {
          status = 'ok'
          message = 'Under target'
        } else if (variation_percentage >= 100) {
          status = 'good'
          message = 'Over target'
        }
      }
      if (metric === 'cpa') {
        if (variation_percentage <= 100) {
          status = 'good'
          message = 'Under target'
        } else if (variation_percentage >= 115) {
          status = 'bad'
          message = 'Over target'
        } else if (variation_percentage >= 105) {
          status = 'ok'
          message = 'Over target'
        }
      }
      if (metric === 'spend') {
        if (variation_percentage > 115) {
          status = 'bad'
          message = 'Over target'
        } else if (variation_percentage > 105) {
          status = 'ok'
          message = 'Over target'
        } else if (variation_percentage < 85) {
          status = 'bad'
          message = 'Under target'
        } else if (variation_percentage < 95) {
          status = 'ok'
          message = 'Under target'
        }
      }
      return { status, message }
    },
    changeTargetGoal(item) {
      this.selectedTarget = item
    },
    async updateSiteMetrics(metric, targetValue) {
      const value = parseFloat(targetValue)
      if (!value) {
        return this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Target cannot be empty',
            type: 'error',
          },
        })
      }
      if (!Number.isInteger(Number(value))) {
        return this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Target must be a whole number',
            type: 'error',
          },
        })
      }
      await this.$http.patch(`common/site/${this.siteId}/metric-targets`, {
        [metric]: value,
      })
      this.$toast.success({
        component: Toast,
        props: {
          title: 'Success',
          message: 'Target updated successfully',
          type: 'success',
        },
      })
      this.$emit('metrics-updated')
    },
    async fetchData() {
      const target = this.siteMetrics.site_metric_target
      // Only fetch data if site_metric_target is not null and has valid metrics
      if (target && (target.spend > 0 || target.roas > 0 || target.cpa > 0)) {
        await this.fetchChartData({
          siteId: this.siteId,
          start_date: this.dateRange.start,
          end_date: this.dateRange.end,
        })
      }
    },
    formatNumber(num) {
      return num ? parseFloat(parseFloat(num).toFixed(0)) : 0
    },
    formatNumberWithSpaces(num) {
      return this.formatNumber(num)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
  },
}
</script>
